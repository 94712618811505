<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="vcard-info">
            <div class="vcard-info-header">
              <div class="avatar align-self-center mr-3 w-60 rounded-circle">
                {{ contact.first_name.charAt(0) }}
              </div>
              <h3>{{ contact.first_name +' '+ contact.last_name }}</h3>
            </div>
            <div class="vcard-info-body">
              <ul>
                <li v-show="contact.company"><span class="material-icons-outlined">business</span>{{ contact.company }}</li>
                <li v-show="contact.mobile_number"><span class="material-icons-outlined">phone</span>{{ contact.mobile_number | maskPhone }}</li>
                <li v-show="contact.email"><span class="material-icons-outlined">email</span>{{ contact.email }}</li>
              </ul>
            </div>
            <form @submit.prevent>
              <div class="vcard-info-footer">
                <button v-modal="{ target: 'create-contact' }" class="btn btn-primary btn-modal-primary to-right"><img data-v-046f87bc="" src="assets/img/icons/svg/person_add.svg" class="c-pointer"><span class="to-left">Criar contato</span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <create-contact-modal
      id="create-contact"
      :vcard="contact"
      @close-modal="closeModal()"
    ></create-contact-modal>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import CreateContactModal from '@/components/rebranding/contacts/CreateContactModal.vue';

export default {
  name: 'vcard-detail-modal',
  mixins: [Modal],
  props: ['id', 'contact'],
  data() {
    return {
      form: {},
      isSending: false,
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        keepInvalid: true,
      },
    };
  },
  filters: {
    maskPhone(phone) {
      if (!phone) return '';

      phone = phone.replace(/\D+/g, '').substring(0, 13);

      switch (phone.length) {
        case 13:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 12:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 11:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        case 10:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        default:
      }

      return phone;
    },
  },
  components: {
    CreateContactModal,
  },
  created() {
    console.log('Message ==>', this.contact);
  },
  methods: {
    closeModal() {
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
  .avatar {
    background-color: var(--clr-yup-purple);
    width: 100px;
    height: 100px;
    text-align: center;
    padding: 20px 0;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 40px;
    color: #fff;
}
  .vcard-info-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .vcard-info-header h3 {
    margin: 1em;
  }

  .vcard-info-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  .vcard-info-body ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .vcard-info-body ul li {
    margin: 1em 0;
    font-weight: 400;
  }

  .vcard-info-body ul li span {
    color: #752de6;
    font-size: 1.5rem;
    vertical-align: middle;
    margin-right: 10px;
  }

  .vcard-info-footer {
    margin: 1em 0;
    display: flex;
    justify-content: center;
  }

  .vcard-info-footer img {
    margin: 0 0.5em;
  }
</style>
