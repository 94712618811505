<template>
  <div class="card">
    <div class="card-body" role="button" v-modal="{ target: 'vcard-detail' }">
      <div class="media">
        <div class="avatar align-self-center mr-3 w-40 rounded-circle">
          {{ contact.first_name.charAt(0) }}
        </div>
        <div class="media-body">
          <p class="mb-0">
            <strong class="vcard-title">{{ `${contact.first_name} ${contact.last_name}` }}</strong>
          </p>
          <span>Ver Contato</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'VCard',
  components: {},
  props: ['contact'],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  background-color: var(--clr-yup-purple);
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 6px 0;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
  color: #fff;
}

.vcard-title {
  color: #752de6;
  font-weight: 600;
}
</style>
