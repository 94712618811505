<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cadastrar contato</h5>
          <button type="button" class="close" @click="closeModal" aria-label="Close">
            <span class="material-symbols-outlined">
            close
            </span>
          </button>
        </div>
        <div class="form-horizontal">
          <div class="modal-body">
            <div class="form-body" v-if="step == 0">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >{{$t('generic-str.lbl-first-name')}}</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.first_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >{{$t('generic-str.lbl-last-name')}}</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.last_name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Data de nascimento</label
                >
                <div class="col-md-12">
                  <!--
                  <input
                    v-model="form.birthdate"
                    type="text"
                    class="form-control"
                    v-mask="'##/##/####'"
                  />
                  -->
                  <date-picker
                    v-model="form.birthdate"
                    :config="{
                      format: 'DD/MM/YYYY',
                      useCurrent: true,
                      minDate: new Date('1900-01-01'),
                      locale: 'pt',
                      keepInvalid: true,
                    }"
                    v-b-tooltip.hover :title="form.birthdate"
                    class="form-control input-date"
                    :placeholder="$t('generic-str.date-hour')" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      v-model="form.gender"
                      value="female"
                      checked
                      id="typeFile"
                      name="file_type"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="typeFile"
                      >Feminino</label
                    >
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      v-model="form.gender"
                      value="text"
                      id="typeText"
                      name="male"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="typeText"
                      >Masculino</label
                    >
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >{{$t('generic-str.lbl-cellphone')}}</label
                >
                <div class="col-md-12">
                  <tel-input
                    ref="mobile_number"
                    v-model="form.mobile_number"
                  />
                  <!-- v-if="isShow" -->
                  <div class="invalid-feedback" :class="{ show: invalidPhone }">
                    {{$t('login-invite.invalid-phone')}}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Email</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>
              <div v-if="!group" class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >{{$t('sms.send-msg.tabs.groups.title')}}</label
                >
                <div class="col-md-12">
                  <select-input
                    multiple
                    label="name"
                    :reduce="(group) => group.id"
                    v-model="form.groups"
                    :options="groups"
                    :clearable="false"
                  />
                </div>
              </div>
              <div>
                <button type="button" class="btn btn-secondary btn-modal-secundary" @click="closeModal">
                  <span class="to-left">Fechar</span>
                </button>
                <button
                  :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                  :disabled="isSending"
                  type="button"
                  class="btn btn-primary btn-modal-primary to-right"
                  @click="nextStep()"
                >
                  <span class="to-left">Próximo</span> <span class="material-symbols-outlined to-left">keyboard_arrow_right</span>
                </button>
              </div>
            </div>
            <div class="form-body" v-if="step == 1">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >CEP</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.zip"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Estado</label
                >
                <div class="col-md-12">
                  <select
                    v-model="form.state"
                    type="text"
                    class="form-control"
                  >
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="EX">Estrangeiro</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Cidade</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.city"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Endereço</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.address"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Número</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.number"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label @click="fillForm()" class="control-label text-left col-md-12 text-left-m"
                  >Complemento</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.complement"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div>
                <button type="button" class="btn btn-secondary btn-modal-secundary" @click="step = 0">
                  <span class="material-symbols-outlined to-left">keyboard_arrow_left</span> <span class="to-left">Voltar</span>
                </button>
                <button
                  :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                  :disabled="isSending"
                  type="button"
                  class="btn btn-primary btn-modal-primary to-right"
                  @click="create"
                >
                  <span class="to-left">Cadastrar</span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import ContactService from '@/services/contact.service';
import Modal from '@/mixins/Modal';
import SelectInput from '@/components/SelectInput.vue';
import TelInput from '@/components/form/TelInput.vue';
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
  components: {
    SelectInput,
    TelInput,
    datePicker,
  },
  mixins: [Modal],
  directives: {
    mask,
  },
  props: ['id', 'group', 'vcard'],
  data() {
    return {
      step: 0,
      groups: [],
      mobile_number: null,
      invalidPhone: false,
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      form: {
        first_name: '',
        last_name: '',
        mobile_number: '',
        email: '',
        groups: [],
        birthdate: '',
        gender: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        number: '',
        complement: '',
      },
      isSending: false,
    };
  },
  created() {
    this.fillForm();
    this.fetchGroups();
  },
  methods: {
    fillForm() {
      if (this.vcard) {
        const keys = Object.keys(this.form);
        keys.forEach((key) => {
          this.form[key] = this.vcard[key];
        });
      }
    },
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.form.mobile_number = rest.number.input;
        }
      }
    },
    fetchGroups() {
      ContactService.getGroups().then(
        (groups) => {
          this.groups = groups;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    nextStep() {
      if (this.validate()) this.step = 1;
    },
    validate() {
      if (!this.form.first_name) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Informe o primeiro nome',
          type: 'danger',
        });
        return false;
      }

      if (!this.form.email && !this.form.mobile_number) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Celular ou email inválido',
          type: 'danger',
        });
        return false;
      }

      return true;
    },
    create() {
      if (this.validate()) {
        this.isSending = true;

        if (this.group) this.form.groups[0] = this.group.id;

        if (this.form.birthdate) {
          // ISO date format
          const date = moment(this.form.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');

          if (date && date.indexOf('valid') === -1) this.form.birthdate = date;
        }

        ContactService.createContact(this.form).then(
          () => {
            this.closeModal();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Contato criado',
              type: 'success',
            });
            this.$emit('sent');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error?.message || error,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      }
    },
    closeModal() {
      // Criado para fechar modal (vcardDEtailModal) caso tenha dados carregados de um vcard.
      this.hide();
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss">
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
</style>
