<template>
  <form v-if="loaded" action="#" class="form-horizontal">
    <div class="row">
      <div class="col-md-4">
        <div class="form-body">
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >ID:</label
            >
            <label class="control-label text-left col-md-9">{{
              $route.params.id
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >{{ $t('generic-str.lbl-sender') }}:</label
            >
            <label class="control-label text-left col-md-9">{{
              message.from
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >{{ $t('generic-str.content') }}:</label
            >
            <label class="control-label text-left col-md-9">
              <template
                v-if="
                  (message.type === 'plain' || message.type === 'text') &&
                  message.body.text
                "
              >
                {{ message.body.text }}
              </template>
              <template v-else-if="message.type === 'file'">
                <img
                  v-if="message.body.file.mime.includes('image')"
                  class="img-thumbnail"
                  :src="message.body.file.url"
                />
                <video
                  v-else-if="message.body.file.mime.includes('video')"
                  controls
                >
                  <source
                    :src="message.body.file.url"
                    :type="message.body.file.mime"
                  />
                  Your browser does not support the video tag.
                </video>
                <audio
                  v-else-if="message.body.file.mime.includes('audio')"
                  controls
                >
                  <source
                    :src="message.body.file.url"
                    :type="message.body.file.mime"
                  />
                  Your browser does not support the audio element.
                </audio>
                <VCard
                  v-else-if="message.body.file.mime === 'text/x-vcard'"
                  :contact="message.body.file.contact"
                ></VCard>
                <div v-else class="card">
                  <a
                    :href="message.body.file.url"
                    class="card-body"
                    target="_blank"
                  >
                    <div class="media">
                      <div class="media-body">
                        <p class="mb-0">
                          <strong class="">{{ message.body.file.name }}</strong>
                        </p>
                        <span>Baixar Arquivo</span>
                      </div>
                    </div>
                  </a>
                </div>
                <VcardDetailModal
                  id="vcard-detail"
                  :contact="message.body.file.contact"
                />
              </template>
              <template v-else-if="message.type === 'media'">
                <img class="img-thumbnail" :src="message.body.media.url" />
              </template>
              <template v-else-if="message.type === 'location'">
                <iframe
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  id="gmap_canvas"
                  :src="`https://maps.google.com/maps?amp;hl=en&amp;q=${message.body.location.latitude},${message.body.location.longitude}&amp;t=&amp;z=21&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
                  width="520"
                  height="400"
                  frameborder="0"
                ></iframe>
              </template>
              <template v-else-if="message.type === 'card'">
                <div class="card">
                  <div class="card-content">
                    <div class="card-img h-200 d-flex">
                      <img
                        class="card-img-top img-fluid"
                        :src="message.body.card.media.url"
                        alt="Card image cap"
                      />
                    </div>
                    <div class="card-body">
                      <h4 class="card-title">
                        {{ message.body.card.title }}
                      </h4>
                      <p class="card-text">
                        {{ message.body.card.text }}
                      </p>
                    </div>
                    <div class="card-footer text-right">
                      <div
                        v-for="(button, n) in message.body.card.buttons"
                        :key="n"
                        class="form-group row"
                      >
                        <hr v-if="n > 0" />
                        <a
                          v-if="button.type === 'dial'"
                          :href="`tel:${button.phone_number}`"
                          role="button"
                          class="col-12 text-center"
                        >
                          <span class="material-icons-outlined"> call </span
                          >{{ button.text }}
                        </a>
                        <a
                          v-else-if="button.type === 'url'"
                          :href="`${button.url}`"
                          target="_blank"
                          role="button"
                          class="col-12 text-center"
                        >
                          <span class="material-icons-outlined"> public </span
                          >{{ button.text }}
                        </a>
                        <a
                          v-else-if="button.type === 'location'"
                          :href="`https://maps.google.com/maps?q=${button.address}`"
                          target="_blank"
                          role="button"
                          class="col-12 text-center"
                        >
                          <span class="material-icons-outlined"> place </span
                          >{{ button.text }}
                        </a>
                        <a
                          v-else-if="button.type === 'event'"
                          target="_blank"
                          role="button"
                          class="col-12 text-center"
                        >
                          <span class="material-icons-outlined">
                            insert_invitation </span
                          >{{ button.text }}
                        </a>
                        <a
                          v-else
                          role="button"
                          href="javascript:void(0)"
                          class="col-12 text-center"
                        >
                          {{ button.text }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="message.type === 'carousel'">
                <div class="row carousel">
                  <div
                    v-for="(card, n) in message.body.carousel.cards"
                    :key="n"
                    class="col-auto"
                    :class="message.body.carousel.width"
                  >
                    <div class="card">
                      <div class="card-content">
                        <div class="card-img d-flex medium">
                          <img
                            class="card-img-top img-fluid"
                            :src="card.media.url"
                            alt="Card image cap"
                          />
                        </div>
                        <div class="card-body">
                          <h4 class="card-title">
                            {{ card.title }}
                          </h4>
                          <p class="card-text">
                            {{ card.text }}
                          </p>
                        </div>
                        <div class="card-footer text-right">
                          <div
                            v-for="(button, n) in card.buttons"
                            :key="n"
                            class="row"
                          >
                            <hr v-if="n > 0" />
                            <a
                              v-if="button.type === 'dial'"
                              :href="`tel:${button.phone_number}`"
                              role="button"
                              class="col-12 text-center"
                            >
                              <span class="material-icons-outlined"> call </span
                              >{{ button.text }}
                            </a>
                            <a
                              v-else-if="button.type === 'url'"
                              :href="`${button.url}`"
                              target="_blank"
                              role="button"
                              class="col-12 text-center"
                            >
                              <span class="material-icons-outlined">
                                public </span
                              >{{ button.text }}
                            </a>
                            <a
                              v-else-if="button.type === 'location'"
                              :href="`https://maps.google.com/maps?q=${button.address}`"
                              target="_blank"
                              role="button"
                              class="col-12 text-center"
                            >
                              <span class="material-icons-outlined">
                                place </span
                              >{{ button.text }}
                            </a>
                            <a
                              v-else-if="button.type === 'event'"
                              target="_blank"
                              role="button"
                              class="col-12 text-center"
                            >
                              <span class="material-icons-outlined">
                                insert_invitation </span
                              >{{ button.text }}
                            </a>
                            <a
                              v-else
                              role="button"
                              href="javascript:void(0)"
                              class="col-12 text-center"
                            >
                              {{ button.text }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-body">
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >{{ $t('generic-str.type') }}:</label
            >
            <label class="control-label text-left col-md-9">
              <template v-if="message.direction == 'outbound'">{{
                $t('generic-str.send-2')
              }}</template>
              <template v-else-if="message.direction == 'inbound'">{{
                $tc('generic-str.status.lbl-response', 1)
              }}</template>
            </label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >{{ $t('generic-str.lbl-recipient') }}:</label
            >
            <label class="control-label text-left col-md-9">{{
              message.to
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >{{ $t('generic-str.sent-in') }}:</label
            >
            <label class="control-label text-left col-md-9">{{
              message.sent_at | date('DD/MM/YYYY HH:mm:ss')
            }}</label>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-body">
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-600"
              >Eventos:</label
            >
            <label class="control-label text-left col-md-9">
              <div class="timeline timeline-border">
                <div
                  v-for="event in message.events"
                  :key="event.id"
                  class="timeline-list timeline-border"
                >
                  <div class="timeline-info">
                    <div v-if="event.name === 'sent'" class="d-inline-block">
                      {{ `${$tc('app.sent_to')} ${event.data}` }}
                    </div>
                    <div
                      v-else-if="event.name === 'delivered'"
                      class="d-inline-block"
                    >
                      {{ `${$tc('app.delivered_to')} ${event.data}` }}
                    </div>
                    <div
                      v-else-if="event.name === 'read'"
                      class="d-inline-block"
                    >
                      {{ `${$tc('app.read_by')} ${event.data}` }}
                    </div>
                    <div
                      v-else-if="event.name === 'click'"
                      class="d-inline-block"
                    >
                      {{ `${$tc('app.clicked_at')} ${event.data}` }}
                    </div>
                    <div v-else class="d-inline-block">
                      {{ $tc(`app.${event.name}`) }}
                    </div>
                    <small class="float-right text-muted">
                      {{
                        event.created_at | date('DD/MM/YYYY HH:mm:ss')
                      }}</small
                    >
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import VCard from '@/components/rebranding/VCard.vue';
import VcardDetailModal from '@/components/rebranding/rcs/VcardDetailModal.vue';

export default {
  name: 'RcsDetailReport',
  components: {
    VCard,
    VcardDetailModal,
  },
  data() {
    return {
      loaded: true,
      message: {},
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loaded = false;
      this.$store
        .dispatch('fetchRcsMessage', this.$route.params.id)
        .then((response) => {
          this.message = response;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-thumbnail {
  max-width: 50%;
}
label {
  font-weight: 300;
}
.weight-600 {
  font-weight: 500;
}
.timeline-list {
  border-color: var(--clr-yup-purple) !important;
  &::after {
    box-shadow: 0 0 0 3px var(--clr-yup-purple) inset !important;
  }
}
.carousel {
  overflow-x: auto;
  display: block ruby;
}
.col-auto.medium {
  width: 232px;
}
.card-img.medium {
  height: 168px;
}
.card-img-top {
  object-fit: cover;
  object-position: center;
}
iframe {
  width: 100%;
  min-height: 400px;
}
video {
  width: 100%;
}
</style>
